import airmeet from "../assets/Airmeet.png"
import canara from "../assets/Canaraweb.png"
import lic from "../assets/LIC.png"
import honeywell from "../assets/Honeywell.png"
import lt from "../assets/LT.png"
import ntpc from "../assets/ntpc.png"
import sbi from "../assets/SBI.png"

const sponsors = [
    {img : airmeet},
    {img : honeywell},
    {img : sbi},
    {img : lt},
    {img : ntpc},
    {img : lic},
    {img : canara}
];

export default sponsors;